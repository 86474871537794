<template>
  <div class="return-point-view">
    <div class="return-points-radio-wrapper">
      <rcc-radio-group :key="updateKey" :options="isAvailableRadioOptions" v-model="isAvailable" />
    </div>

    <div class="table-wrapper">
      <rcc-table
        :key="updateKey"
        :page.sync="page"
        :pageCount="pagesCount"
        :itemsPerPage="rowsOnTable"
        :headers="headers"
        :rows="rows"
        :is-fixed-header="true"
        :isLoading="isDataLoading"
        @filters-changed="handleFiltersChanged"
      >
        <template #body>
          <rcc-row
            v-for="(row, index) in rows"
            :key="index"
            :headers="headers"
            :row="row"
            :class="getRowClasses(row)"
            v-bind="$attrs"
            v-on="$listeners"
          >
            <template #address-cell="{ value }">
              <rcc-open-on-hover-menu>
                <template #menu-activator>
                  <div class="address">
                    {{ value }}
                  </div>
                </template>

                <template #menu>
                  {{ value }}
                </template>
              </rcc-open-on-hover-menu>
            </template>

            <template #delivery-cell="{ value }">
              {{ getDeliveryTime(value) }}
            </template>

            <template #note-cell="{ value }">
              <div class="note-wrapper" v-if="value">
                <rcc-open-on-hover-menu>
                  <template #menu-activator>
                    <div class="note">
                      <v-icon small>
                        mdi-alert-circle
                      </v-icon>
                    </div>
                  </template>

                  <template #menu>
                    {{ value }}
                  </template>
                </rcc-open-on-hover-menu>
              </div>
            </template>
          </rcc-row>
        </template>
      </rcc-table>
    </div>
  </div>
</template>

<script>
import TableList from '@/mixins/table-list'
import ReturnPointsApi from '@/api/return-points'
import headerData from './header'

import RccRadioGroup from 'Components/controls/radio-group/rcc-radio-group'
import RccTable from 'Components/table/rcc-table'
import RccOpenOnHoverMenu from 'Components/ui/open-on-hover-menu'
import RccRow from 'Components/table/rcc-row'

export default {
  mixins: [TableList, ReturnPointsApi],

  components: {
    RccRadioGroup,
    RccTable,
    RccOpenOnHoverMenu,
    RccRow
  },

  data() {
    return {
      updateKey: 0,
      isAvailable: true
    }
  },

  props: {
    tradingNetworkId: {
      type: Number,
      default: null
    },

    isActive: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    headerData() {
      return headerData.filter(({ access, showOnNetworks }) => (
        access.includes(this.$store.user.role) &&
          (!showOnNetworks?.length || showOnNetworks.includes(this.tradingNetworkId))
      ))
    },

    isAvailableRadioOptions() {
      return [
        { value: true, label: 'Активный' },
        { value: false, label: 'Заблокирован' }
      ]
    },

    isX5Network() {
      return this.tradingNetworkId === this.$constants.networkIds.x5
    },

    headerFiltersData() {
      return [
        {
          filter: 'name',
          name: 'return_point_id',
          callback: this.$apiMethods.returnPoints.dcList,
          access: true
        },
        {
          filter: 'region',
          name: 'region_id',
          callback: this.$apiMethods.regions.allList,
          access: this.isX5Network
        }
      ].filter(({ access }) => access)
    }
  },

  watch: {
    isActive(value) {
      if (value) {
        this.$nextTick(() => {
          // Необходимо для пересчета высоты таблицы и установки активной страницы в пагинации
          this.updateKey += 1
        })
      }
    },

    isAvailable() {
      this.page = 1
      this.loadData(this.filtersQuery)
    }
  },

  created() {
    this.initFilters()
  },

  methods: {
    initFilters() {
      this.headerFiltersData.forEach(filter => {
        this.setFilter(filter)
      })
    },

    setFilter({ filter, name, callback, type = 'multiple-autocomplete' }) {
      const index = this.headerData.findIndex(item => item.value === filter)

      if (index < 0) {
        return
      }

      this.headerData[index].customFilter.name = name
      this.headerData[index].customFilter.callback = callback
    },

    loadData(filters) {
      this.isDataLoading = true
      const params = {
        page: this.page,
        per_page: this.rowsOnTable,
        network_id: this.tradingNetworkId,
        is_available: this.isAvailable,
        ...filters
      }

      this.$apiMethods.returnPoints.list(params)
        .then(({ pages, items }) => {
          if (!pages) {
            this.pagesCount = 1
            this.rows = []
            return
          }

          this.pagesCount = pages
          this.rows = items
        })
        .finally(() => { this.isDataLoading = false })
    },

    getDeliveryTime(value) {
      const [start, end] = value

      if (!start || !end) {
        return ''
      }

      if (start && end) {
        return `${start.slice(0, 5)} - ${end.slice(0, 5)}`
      }

      return start || end
    },

    getRowClasses(row) {
      return [
        row.recent && 'is-new'
      ]
    }
  }
}
</script>

<style lang="scss">
.return-point-view {
  height: 100%;
  display: flex;
  flex-direction: column;

  .return-points-radio-wrapper {
    margin-bottom: 10px;
  }

  .note-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .note {
    i {
      color: $error;
    }
  }

  .table-wrapper {
    flex-grow: 1;
  }

  .address {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 160px;
  }

  .address-cell {
    width: 160px;
  }

  tr.is-new {
    background: #f9f3d06e;
  }
}
</style>
