import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"return-point-view"},[_c('div',{staticClass:"return-points-radio-wrapper"},[_c('rcc-radio-group',{key:_vm.updateKey,attrs:{"options":_vm.isAvailableRadioOptions},model:{value:(_vm.isAvailable),callback:function ($$v) {_vm.isAvailable=$$v},expression:"isAvailable"}})],1),_c('div',{staticClass:"table-wrapper"},[_c('rcc-table',{key:_vm.updateKey,attrs:{"page":_vm.page,"pageCount":_vm.pagesCount,"itemsPerPage":_vm.rowsOnTable,"headers":_vm.headers,"rows":_vm.rows,"is-fixed-header":true,"isLoading":_vm.isDataLoading},on:{"update:page":function($event){_vm.page=$event},"filters-changed":_vm.handleFiltersChanged},scopedSlots:_vm._u([{key:"body",fn:function(){return _vm._l((_vm.rows),function(row,index){return _c('rcc-row',_vm._g(_vm._b({key:index,class:_vm.getRowClasses(row),attrs:{"headers":_vm.headers,"row":row},scopedSlots:_vm._u([{key:"address-cell",fn:function(ref){
var value = ref.value;
return [_c('rcc-open-on-hover-menu',{scopedSlots:_vm._u([{key:"menu-activator",fn:function(){return [_c('div',{staticClass:"address"},[_vm._v(" "+_vm._s(value)+" ")])]},proxy:true},{key:"menu",fn:function(){return [_vm._v(" "+_vm._s(value)+" ")]},proxy:true}],null,true)})]}},{key:"delivery-cell",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.getDeliveryTime(value))+" ")]}},{key:"note-cell",fn:function(ref){
var value = ref.value;
return [(value)?_c('div',{staticClass:"note-wrapper"},[_c('rcc-open-on-hover-menu',{scopedSlots:_vm._u([{key:"menu-activator",fn:function(){return [_c('div',{staticClass:"note"},[_c(VIcon,{attrs:{"small":""}},[_vm._v(" mdi-alert-circle ")])],1)]},proxy:true},{key:"menu",fn:function(){return [_vm._v(" "+_vm._s(value)+" ")]},proxy:true}],null,true)})],1):_vm._e()]}}],null,true)},'rcc-row',_vm.$attrs,false),_vm.$listeners))})},proxy:true}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }